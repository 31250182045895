import React, { useEffect } from "react"

import Helmet from "react-helmet"

import Layout from '../components/layout'
import Section, { TextSection } from '../components/Section'


import { MemberSection } from "../components/MemberArea"


const Homepage = () => {
    // const [ headerData, setHeaderData ] = useState( [ ] )
    const siteTitle = "Blackheath and Charlton Baptist Church"

    useEffect( () => {
        
        
    }, [] )
   
    const memberListHeaders = [ "name", "group", "role" ]
    return (
        
        <>
            <Helmet title={siteTitle} />

            <MemberSection />


            <Section
                id="giving"
                className="style1"
                title="Giving"
                description="A paragraph talking about donations and how you can aid the church..."
                buttons={[ { url: "/giving", text: "Donate Now", special: true } ]}                
             />

            <TextSection 
                id="mission-map"
                style="style2"
                title="Missionary Work"
                description="Quick description of our missionary work across the globe"
                buttons={[ { url: '/missions', text: "See More" } ]}
            />


        </>
    )
}

export default Homepage