import React, { useEffect } from 'react'
import { ContainerSection } from '../Section'
import { useData } from '../../lib/hooks'
import { getAllUsers } from '../../lib/API'

const memberListHeaders = [ "Name", "Email", "Telephone", "Group", "Role" ]


const MemberList = ( { members } ) => members.map( ( { firstname, lastname, role, group, email, telephone } ) => (
        <tr className="member-row">
            <td>{firstname + " " + lastname}</td>
            <td>{email}</td>
            <td>{telephone}</td>
            <td>{group}</td>
            <td>{role.name}</td>
        </tr>
    ) 
)


export const MemberSection = () => {

    const members = useData( getAllUsers )
    
    return (
        <ContainerSection>
        <header><h2 class="major">Members</h2></header>
            <table className="member-section" style={{ alignText: "center" }}>
                <tr style={{ textAlign: "center" }}>
                    {memberListHeaders.map( header => <th style={{ textAlign: "center", borderBottom: "1px dotted black" }}>{header}</th> )}
                </tr>
                <MemberList members={members} />
            </table>
        </ContainerSection>
    )
}